import React from "react";
import reflectionPicture from "../pictures/PALLADIO_K1solopannello1.png";
import picture from "../pictures/middle.png";

const reflectionImage = new Image();
reflectionImage.src = reflectionPicture;

const image = new Image();
image.src = picture;

class Door extends React.Component {
  canvasRectUpdate() {
    const width = this.props.width;
    const height = this.props.height;
    const canvas = this.refs.canvas;
    const centreOfCanvas = { horizontal: canvas.height * 0.5, vertical: canvas.width * 0.5 };
    const centreImage = {
      horizontal: centreOfCanvas.horizontal - height * 0.5,
      vertical: centreOfCanvas.vertical - width * 0.5
    };
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#571a24";
    ctx.fillRect(centreImage.vertical, centreImage.horizontal, width, height);
    ctx.globalCompositeOperation = "source-over";
    ctx.drawImage(
      image,
      centreOfCanvas.vertical - image.width * 0.75,
      centreOfCanvas.horizontal - image.height * 0.85,
      image.width * 1.5,
      image.height * 1.7
    );
    ctx.globalCompositeOperation = "overlay";
    ctx.drawImage(reflectionImage, centreImage.vertical, centreImage.horizontal, width, height);
    canvas.style.width = `${this.props.canvasWidth / 8}px`;
    canvas.style.height = `${this.props.canvasHeight / 8}px`;
    canvas.style.marginTop = "-50px";
    canvas.style.marginLeft = "150px";
  }

  componentDidMount() {
    reflectionImage.onload = () => {
      this.canvasRectUpdate();
    };
    image.onload = () => {
      this.canvasRectUpdate();
    };
  }
  componentDidUpdate() {
    this.canvasRectUpdate();
  }

  render() {
    return <canvas ref="canvas" height={this.props.canvasHeight} width={this.props.canvasWidth} />;
  }
}

export default Door;
