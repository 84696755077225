import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/lab/Slider';

const styles = {
  root: {
    width: 400,
  },
  slider: {
    padding: '22px 0px',
  },
};

class StepSlider extends React.Component {
  state = {
    value: this.props.max - ((this.props.max - this.props.min) / 2),
  };

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.onSubmit(this.state.value);
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <div>
          {value}
        </div>
        <Slider
          classes={{ container: classes.slider }}
          value={value}
          min={this.props.min}
          max={this.props.max}
          step={1}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

StepSlider.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StepSlider);