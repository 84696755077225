import React from "react";
import StepSlider from "./Slider";
import Door from "./Door";
import "./App.css";

class App extends React.Component {
  state = {
    doorWidth: null,
    doorHeight: null,
    widthRange: { min: 65, max: 125 },
    heightRange: { min: 182, max: 250 },
    scale: 20
  };

  componentDidMount() {
    this.setState({
      doorWidth:
        (this.state.widthRange.max - (this.state.widthRange.max - this.state.widthRange.min) / 2) *
        this.state.scale
    });
    this.setState({
      doorHeight:
        (this.state.heightRange.max -
          (this.state.heightRange.max - this.state.heightRange.min) / 2) *
        this.state.scale
    });
  }

  canvasRef = React.createRef();

  getDoorHeight = value => {
    this.setState({ doorHeight: value * this.state.scale });
  };

  getDoorWidth = value => {
    this.setState({ doorWidth: value * this.state.scale });
  };

  render() {
    return (
      <div className="flex-container">
        <div className="sliders">
          <StepSlider
            onSubmit={this.getDoorWidth}
            min={this.state.widthRange.min}
            max={this.state.widthRange.max}
          />
          <StepSlider
            onSubmit={this.getDoorHeight}
            min={this.state.heightRange.min}
            max={this.state.heightRange.max}
          />
        </div>
        <div className="canvas" ref={this.canvasRef}>
          <Door
            height={this.state.doorHeight}
            width={this.state.doorWidth}
            canvasHeight={6000}
            canvasWidth={4000}
          />
        </div>
      </div>
    );
  }
}

export default App;
